<script>
import ResourceFrame from "@/screens/resourcesIndex/components/ResourceFrame.vue";
import { SuawInputGroup, SuawHeading } from "@/components";

export default {
  name: "AcademicOrganizers",
  components: { ResourceFrame, SuawInputGroup, SuawHeading }
};
</script>

<template>
  <ResourceFrame resource-side-nav-type="organizer">
    <SuawInputGroup direction="column" group-gap="quadruple" prevent-flex-grow>
      <h1 class="suaw-organizer-resource-heading">Academic <b>Organizers</b></h1>
      <SuawHeading
        class="suaw-organizer-resource-intro"
        level="3"
        content="Are you interested in starting a Shut Up & Write! event at your college or university? Hundreds of universities worldwide leverage Shut Up & Write! to benefit their academic communities."
      />

      <h2 class="suaw-organizer-resource-subtitle">
        Key Benefits
      </h2>
      <p class="suaw-paragraph">
        From heartfelt testimonials to personal stories, we've seen consistent benefits for academics who regularly join our events:
      </p>
      <div class="suaw-organizer-resource-guide">
        <ul class="suaw-organizer-resource-guide__list">
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Boost Productivity:</b> Find your focus and get more done.
            </p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Build Community:</b> Overcome academic isolation by connecting with peers across disciplines and hierarchies.
            </p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Share Skills Naturally:</b> Promote informal learning and exchange technical know-how in a relaxed setting.
            </p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Supportive Space:</b> Enjoy a consistent, comfortable environment dedicated to writing.
            </p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Networking Opportunities:</b> Discover natural avenues for collaboration and new connections.
            </p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Intrinsic Motivation:</b> Rekindle your passion for writing, free from external pressures.
            </p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Confidence and Calm:</b> Increase self-efficacy while reducing anxiety around writing.
            </p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Enjoy the Process:</b> Rediscover the joy in your writing journey.
            </p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Healthy Habits:</b> Develop sustainable writing practices that support your well-being.
            </p>
          </li>
        </ul>
      </div>

      <h2 class="suaw-organizer-resource-subtitle">
        In-Person or Online?
      </h2>
      <p class="suaw-paragraph">
        In the university setting, both in-person and online events offer unique advantages:
      </p>
      <div class="suaw-organizer-resource-guide">
        <ul class="suaw-organizer-resource-guide__list">
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>In-Person Events:</b> Hosting face-to-face sessions can revitalize the on-campus community spirit, especially after extended periods of virtual interaction. Plus, you might find funding for tea, coffee, or even pastries to sweeten the deal!
            </p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Online Events:</b> Ideal for universities with multiple campuses, remote students, international students in different time zones, or those balancing work and family. Online sessions create inclusive communities that transcend physical boundaries and facilitate collaboration across sister schools or larger academic networks.
            </p>
          </li>
        </ul>
      </div>

      <h2 class="suaw-organizer-resource-subtitle">
        Flexible Format
      </h2>
      <p class="suaw-paragraph">
        Feel free to adapt the <router-link :to="{ name: 'HostingYourInPersonEvent' }">standard Shut Up & Write! format</router-link> to suit your group's needs:
      </p>
      <div class="suaw-organizer-resource-guide">
        <ul class="suaw-organizer-resource-guide__list">
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Pomodoro-Style Breaks:</b> Incorporate 5-10 minute breaks after each 20-25 minute writing sprint. These intervals are especially helpful for academic writers to recharge and connect.
            </p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Event Length:</b> Sessions can range from one to three hours. The longer the event, the more we recommend building in breaks for relaxation and conversation.
            </p>
          </li>
        </ul>
      </div>

      <h2 class="suaw-organizer-resource-subtitle">
        Co-Host Considerations
      </h2>
      <div class="suaw-organizer-resource-guide">
        <ul class="suaw-organizer-resource-guide__list">
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Collaborate Creatively:</b> A co-host doesn't have to be someone from your team or department. Consider partnering with other departments, colleges within the university, or even your venue. Librarians, for instance, can be fantastic allies in finding space, promoting events, and fostering a welcoming atmosphere.
            </p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Empower Students:</b> Don't overlook the opportunity to involve students as organizers or co-hosts. These roles can be incredibly rewarding and motivate them to participate regularly and bring along their peers.
            </p>
          </li>
        </ul>
      </div>

      <h2 class="suaw-organizer-resource-subtitle">
        Listing and Promoting Your Event
      </h2>
      <p class="suaw-paragraph">
        Spread the word using a mix of channels:
      </p>
      <div class="suaw-organizer-resource-guide">
        <ul class="suaw-organizer-resource-guide__list">
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>University Website:</b> Regular posts on your university's site are a great starting point.
            </p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Blog Posts:</b> Write about the benefits of Shut Up & Write! and what attendees can expect.
            </p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Newsletters:</b> Feature your event in university or departmental newsletters targeted at your audience—whether postgrads, undergrads, or staff.
            </p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Venue Partnerships:</b> If hosting in person, leverage additional promotion options through your venue. Librarians and café owners may be eager to help advertise your event.
            </p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Social Media:</b> Share your event on social platforms. We have editable <router-link :to="{ name: 'EventsToolkit', hash: '#social-media' }">social media graphics</router-link> to help catch people's attention.
            </p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Flyers:</b> Leave printed flyers around campus hotspots like coffee shops, libraries, and bookstores. <router-link :to="{ name: 'EventsToolkit', hash: '#flyers' }">Download PDF flyers here.</router-link>
            </p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Word of Mouth:</b> Personal invitations and recommendations go a long way. Let colleagues and students know that their presence matters and can make a real difference in building a vibrant community.
            </p>
          </li>
        </ul>
      </div>

      <p class="suaw-paragraph">
        Looking to start your own academic Shut Up & Write! event? We'd love to connect with you first. We can help guide you through the options for your event and share more with you about the opportunities for longterm partnership and collaboration. Reach out to us at <a href='mailto:academia@suaw.org'>academia@suaw.org</a> and let us know a bit about yourself and your community.
      </p>
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-paragraph {
  color: var(--sem-color-text-medium);
  line-height: var(--p-line-height);
  font-size: var(--p-font-size);
  font-weight: var(--p-font-weight);
}

.suaw-organizer-resource-intro {
  color: var(--sem-color-neutral-dark);
}
.suaw-organizer-resource-heading {
  margin: 0;
  padding: 0;
  color: #414142;
  font-family: Archer;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.suaw-organizer-resource-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}
.suaw-organizer-resource-thumbnail {
  aspect-ratio: 2;
  @media (max-width: 650px) {
    aspect-ratio: 1.5;
  }
}
.suaw-organizer-resource-button {
  @media (max-width: 650px) {
    width: 100%;
  }
}
//list
.suaw-organizer-resource-guide {
  font-family: Roboto;
  color: var(--sem-color-text-medium);

  &__list {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item::marker {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
